<template>
  <div
    :class="[
      'panel border-right border-left',
      $route.query.isMobile == 'true' ? 'mobile-panel' : '',
    ]"
  >
    <div class="border-bottom px-3 py-2">
      <div class="pb-1">
        <b-tabs v-model="tabIndex" content-class="mt-1 px-3" fill>
          <b-tab @click="handleHandleSelectType(1)">
            <template #title>
              <strong>Require Admin</strong>
            </template>
          </b-tab>
          <b-tab @click="handleHandleSelectType(0)">
            <template #title>
              <strong class="px-4">All</strong>
            </template>
          </b-tab>
        </b-tabs>
      </div>
      <div class="">
        <div class="input-group">
          <input
            type="text"
            placeholder="Search chat..."
            name=""
            class="form-control search"
            v-model="textSearchInput"
            @keypress="handleInputSearch"
          />
          <div class="input-group-prepend" @click="handleSearch">
            <span class="input-group-text search_btn">
              <font-awesome-icon :icon="['fas', 'search']" class=""
            /></span>
          </div>
          <div class="input-group-prepend pl-2">
            <b-form-checkbox
              id="checkboxfilterCurrentAdmin"
              v-model="filterCurrentAdmin"
              name="checkboxfilterCurrentAdmin"
              :value="true"
              :unchecked-value="false"
              @change="handleChangeCurrentAdmin"
              class="my-auto"
            >
              My case
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="card-chat mb-sm-3 mb-md-0 contacts_card-chat">
      <div v-if="!isError" class="card-chat-body contacts_body">
        <div
          v-for="item in conversation.chatList"
          :key="item.id"
          :class="['contacts py-0', item.id === conversationId ? 'active' : '']"
          @click="
            () => {
              $emit('select-chat', item);
              setReadConversation(item.id);
            }
          "
        >
          <li class="">
            <div class="d-flex bd-highlight">
              <div class="img_cont">
                <div
                  v-if="
                    item.lastMessage &&
                      item.imageUrl &&
                      item.imageUrl.trim() != ''
                  "
                  class="rounded-circle user_img"
                >
                  <div
                    class="rounded-circle user_img_display"
                    v-bind:style="{
                      backgroundImage: 'url(' + item.imageUrl + ')',
                    }"
                  ></div>
                </div>
                <font-awesome-icon
                  v-else
                  :icon="['fas', 'user']"
                  class="rounded-circle user_img p-1"
                />
              </div>
              <div class="user_info">
                <span class="text-truncate">{{ item.name || "Unknow" }}</span>
                <p
                  v-if="item.lastMessage"
                  :class="[
                    'mb-0',
                    item.lastMessage.adminRead == 0 &&
                    item.lastMessage.userType == 'user'
                      ? 'unread-conversation'
                      : '',
                  ]"
                >
                  <font-awesome-icon
                    v-if="item.lastMessage.userType == 'admin'"
                    :icon="['fas', 'reply']"
                  />
                  {{ `${item.lastMessage.message || " "}` }}
                </p>
                <p v-else class="mb-0" style="visibility: hidden;">-</p>
                <div class="tag-social">
                  <img
                    v-if="
                      $store.getters.getChatSocialList.length &&
                        $store.getters.getChatSocialList.findIndex(
                          (t) => t.id == item.socialType
                        ) >= 0
                    "
                    :src="
                      $store.getters.getChatSocialList[
                        $store.getters.getChatSocialList.findIndex(
                          (t) => t.id == item.socialType
                        )
                      ].imageUrl
                    "
                    alt=""
                  />
                  <span
                    v-if="
                      $store.getters.getChatSocialList.length &&
                        $store.getters.getChatSocialList.findIndex(
                          (t) => t.id == item.socialType
                        ) >= 0
                    "
                    class="mb-0"
                    >{{
                      $store.getters.getChatSocialList[
                        $store.getters.getChatSocialList.findIndex(
                          (t) => t.id == item.socialType
                        )
                      ].name
                    }}</span
                  >
                </div>
              </div>
              <div
                v-if="item.lastMessage"
                :class="[
                  'update-time-inbox ml-auto text-right',
                  item.lastMessage.adminRead == 0 &&
                  item.lastMessage.userType == 'user'
                    ? 'text-black'
                    : '',
                ]"
              >
                <ChatDateTime
                  v-if="item.lastMessage"
                  :date="item.lastMessage.createdTime"
                />
                <div class="py-1">
                  <div
                    v-if="
                      item.lastMessage.adminRead == 0 &&
                        item.lastMessage.userType == 'user'
                    "
                    class="ml-auto unread-message font-weight-bold"
                  ></div>
                  <div v-else style="visibility: hidden">
                    -
                  </div>
                </div>
                <div class="text-right text-con-mode">
                  <span v-if="item.mode == 1">
                    <font-awesome-icon :icon="['fas', 'user']" />
                    <span v-if="item.adminName">
                      {{ item.adminName }}
                    </span>
                    <span v-else class="ml-1">Admin Mode</span>
                  </span>
                  <span v-else>
                    <font-awesome-icon :icon="['fas', 'robot']" />
                    Bot Mode
                  </span>
                </div>
              </div>
            </div>
          </li>
        </div>
        <infinite-loading
          spinner="spiral"
          @infinite="scrollMethod"
          ref="infiniteLoadingChatConversation"
        >
          <div
            slot="spinner"
            class="spinner-border text-secondary p-2"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
        <div class="loading-prepare" v-if="isLoading && !isLoadingInfinite">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          v-if="conversation.chatList.length < 1 && !isLoading"
          class="reload-chat"
        >
          <div class="text-center">
            <p>ไม่พบรายการแชท</p>
          </div>
        </div>
      </div>
      <div v-if="isError" class="reload-chat">
        <div class="text-center">
          <p>Network error!!</p>
          <button class="btn btn-primary btn-sm" @click="prepareData">
            Reload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatDateTime from "@/components/chat/ChatDateTime";
export default {
  name: "ChatList",
  components: {
    ChatDateTime,
  },
  props: {
    conversationId: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      tabIndex: 0,
      selectedMode: 1,
      countUnread: {
        admin: 0,
        all: 0,
      },
      isError: false,
      isLoading: false,
      isLoadingInfinite: false,
      chatList: {},
      conversation: {
        chatList: [],
        total: 0,
      },
      textSearchInput: "",
      limit: 14,
      startingAfter: "",
      filterCurrentAdmin: false,
    };
  },
  methods: {
    prepareData() {
      this.isError = false;
      this.getChatList(false);
    },
    async getChatList(scrollFlag, $state) {
      if (!this.$cookies.isKey("kerry-session-token")) {
        window.location.href = `${this.$backofficeUrl}/login`;
      }

      this.isLoading = true;
      if (this.conversation.chatList.length > 0) {
        this.startingAfter = this.conversation.chatList[
          this.conversation.chatList.length - 1
        ].id;
      }

      await fetch(
        `${this.$chatBaseUrl}/chatroom?start=${this.startingAfter ||
          ""}&length=${this.limit || "0"}&adminMode=${this.selectedMode ||
          "0"}&searchName=${
          this.textSearchInput.trim() === "" ? "" : this.textSearchInput
        }&brandId=${this.$route.query.brandID || "0"}&isCurrentAdmin=${this
          .filterCurrentAdmin || "false"}`,
        {
          method: "GET",
          headers: new Headers(this.$headersChat),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          this.isLoading = false;
          this.isLoadingInfinite = false;
          this.startingAfter = "";
          if (result.result) {
            if (scrollFlag) {
              if (result.detail.length == 0) {
                if ($state) $state.complete();
              } else {
                // this.conversation.chatList = [
                //   ...this.conversation.chatList,
                //   ...result.detail,
                // ];

                // check already have id in list -> dont push
                for (let i = 0; i < result.detail.length; i++) {
                  if (
                    this.conversation.chatList.filter(
                      (chat) => chat.userId == result.detail[i].userId
                    ).length == 0
                  ) {
                    this.conversation.chatList.push(result.detail[i]);
                  }
                }
                if ($state) $state.loaded();
              }
            } else {
              this.conversation.chatList =
                result.detail === null ? [] : result.detail;
              if ($state) $state.loaded();
            }

            // // set new admin name
            // if (this.newAdminChatName) {
            //   for (let i = 0; i < this.conversation.chatList.length; i++) {
            //     if (
            //       this.conversation.chatList[i].id ==
            //       this.newAdminChatName.chatID
            //     ) {
            //       this.conversation.chatList[
            //         i
            //       ].adminName = this.newAdminChatName.adminName;
            //     }
            //   }
            // }
          } else {
            this.isError = true;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.isError = true;
          this.isLoading = false;
        });
    },
    scrollMethod($state) {
      if (!this.isLoading) {
        setTimeout(async () => {
          this.startingAfter =
            this.conversation.chatList.length > 0
              ? this.conversation.chatList[
                  this.conversation.chatList.length - 1
                ].conversationId
              : "";
          this.isLoadingInfinite = true;
          this.getChatList(true, $state);
        }, 500);
      } else {
        $state.reset();
      }
    },
    handleHandleSelectType(val) {
      this.selectedMode = val;
      if (this.$refs.infiniteLoadingChatConversation) {
        this.$refs.infiniteLoadingChatConversation.stateChanger.reset();
      }
      this.conversation = {
        chatList: [],
        total: 0,
      };
      this.getChatList(false);
    },
    handleInputSearch(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.handleSearch();
      }
    },
    handleSearch() {
      if (this.$refs.infiniteLoadingChatConversation) {
        this.$refs.infiniteLoadingChatConversation.stateChanger.reset();
      }
      this.conversation = {
        chatList: [],
        total: 0,
      };
      this.getChatList(false);
    },
    handleChangeCurrentAdmin() {
      if (this.$refs.infiniteLoadingChatConversation) {
        this.$refs.infiniteLoadingChatConversation.stateChanger.reset();
      }
      this.conversation = {
        chatList: [],
        total: 0,
      };
      this.getChatList(false);
    },
    getChatDetail(lastMessageId) {
      fetch(`${this.$chatBaseUrl}/message/${lastMessageId}`, {
        method: "GET",
        headers: new Headers(this.$headersChat),
      })
        .then((response) => response.json())
        .then(async (result) => {
          if (result.result == 1) {
            this.conversation.chatList.forEach((item, i) => {
              if (lastMessageId === item.lastMessageId) {
                this.$set(this.conversation.chatList, i, {
                  ...this.conversation.chatList[i],
                  lastMessage: result.detail,
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.isError = true;
          this.isLoading = false;
        });
    },
    reloadDetailList() {
      this.handleSearch();
    },
    setReadConversation(conversationId) {
      this.conversation.chatList.forEach((con, i) => {
        if (con.id === conversationId) {
          this.$set(this.conversation.chatList, i, {
            ...this.conversation.chatList[i],
            lastMessage: { ...con.lastMessage, adminRead: 1 },
          });
          this.$store.commit("setImgUrlCurrentChat", con.imageUrl);
          this.$store.commit("setNameCurrentChat", con.name);
        }
      });
    },
  },
  computed: {
    newAdminChatName: function() {
      return this.$store.state.newAdminChatName;
    },
    newChatItem: function() {
      return this.$store.state.newChatItem;
    },
  },
  created() {
    this.prepareData();
    // this.$store.commit("setNewAdminChatName", {
    //   chatID: "62cfaae7263e7b0d816eb219",
    //   adminName: "ok",
    // });
  },
  watch: {
    "$store.state.isLoadChatConversationList": function() {
      if (this.$store.state.isLoadChatConversationList) {
        // let isHaveCon = false;
        let conUpdateList = this.$store.getters.getChatConversationUpdate;
        this.conversation.chatList.forEach((item, i) => {
          conUpdateList.forEach((con) => {
            if (con.chatroomId === item.id) {
              // isHaveCon = true;
              this.$set(this.conversation.chatList, i, {
                ...this.conversation.chatList[i],
                lastMessage: con,
              });
              const tempCon = this.conversation.chatList[i];
              this.conversation.chatList.splice(i, 1);
              this.conversation.chatList.unshift(tempCon);
            }
          });
        });
        let temCon = this.$store.getters.getChatConversationUpdate;
        temCon.shift();
        this.$store.commit("UPDATE_CHAT_CONVERSATION_UPDATE", temCon);
      }
    },
    "$store.state.newAdminChatName": function() {
      // this.getChatList(false);
      // set new admin name
      for (let i = 0; i < this.conversation.chatList.length; i++) {
        if (this.conversation.chatList[i].id == this.newAdminChatName.chatID) {
          this.conversation.chatList[
            i
          ].adminName = this.newAdminChatName.adminName;
        }
      }
    },
    "$store.state.newChatItem": function() {
      if (this.selectedMode) {
        // tab require admin
        if (this.$store.getters.getNewChatItem.isToAdmin) {
          this.conversation.chatList = [
            this.$store.getters.getNewChatItem.chatroom,
          ].concat(this.conversation.chatList);
        } else {
          let newChatId = this.$store.getters.getNewChatItem.chatroom.id;
          this.conversation.chatList = this.conversation.chatList.filter(
            function(item) {
              return item.id != newChatId;
            }
          );
        }
      } else {
        if (this.$store.getters.getNewChatItem.isToAdmin) {
          let newChatId = this.$store.getters.getNewChatItem.chatroom.id;

          this.conversation.chatList = this.conversation.chatList.filter(
            function(item) {
              return item.id != newChatId;
            }
          );
          this.conversation.chatList = [
            this.$store.getters.getNewChatItem.chatroom,
          ].concat(this.conversation.chatList);
        } else {
          for (let i = 0; i < this.conversation.chatList.length; i++) {
            if (
              this.conversation.chatList[i].id ==
              this.$store.getters.getNewChatItem.chatroomId
            ) {
              this.conversation.chatList.splice(i, 1);
              this.conversation.chatList = [
                this.conversation.chatList[i],
              ].concat(this.conversation.chatList);
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  height: 100%;
  margin: 0;
}
.card-chat {
  height: calc(100% - 83px - 24px);
  background-color: #f6f6f6;
  border-radius: 0;
}
.contacts_body {
  height: 100%;
  padding: 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.contacts_body::-webkit-scrollbar {
  width: 0.35em;
}
.contacts_body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(177, 175, 175, 0.3);
}
.contacts_body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: 1px solid #c1c1c1;
}
.card-chat-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-chat-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.search {
  border-radius: 15px 0 0 15px !important;
  background-color: #f7f7f7;
  border: 1 !important;
  border-right: none;
  border-color: #cccccc;
  color: #707070 !important;
}
.search:focus {
  box-shadow: #707070 !important;
  outline: 1px !important;
}
.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: #f7f7f7 !important;
  border: 1 !important;
  border-left: none;
  border-color: #cccccc;
  color: #707070 !important;
  cursor: pointer;
}
.contacts {
  list-style: none;
  padding: 0;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
  background-color: #ffffffbb;
}
.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  // margin-top: 10px !important;
  // margin-bottom: 10px !important;
}
.active {
  background-color: #eeeeee;
}
.user_img {
  height: 36px;
  width: 36px;
  border: 1.5px solid #f5f6fa;
  background-color: #fff;
}
.user_img_display {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.img_cont {
  position: relative;
  height: 36px;
  width: 36px;
  margin: auto 0;
}
.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  overflow: hidden;
  padding-right: 15px;
}
.user_info span {
  font-size: 14px;
  color: rgb(0, 0, 0);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.user_info p {
  font-size: 12px;
  color: #9c9c9c;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.unread-conversation {
  color: #000 !important;
  font-size: 14px !important;
}
.update-time-inbox {
  position: relative;
  font-size: 13px;
  width: auto;
  color: #9c9c9c;
  margin: auto 0;
}
.unread-message {
  top: 0;
  right: -8px;
  background-color: #832abf !important;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
}
.text-black {
  color: #000 !important;
}
.tag-social {
  display: flex;
  flex-flow: row;
  margin-top: 3px;
}
.tag-social img {
  height: 15px;
  width: auto;
}
.tag-social span {
  font-size: 12px;
  color: #9c9c9c;
  margin: auto 0 auto 5px;
}

.reload-chat,
.loading-prepare {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.spinner-border {
  width: 20px;
  height: 20px;
}

.text-con-mode {
  font-size: 12px;
}
.text-con-mode span {
  margin: auto 0;
  color: #616161;
}
// select {
//   border: none;
// }
::v-deep .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  color: #707070 !important;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 0px solid #832abf !important;
  color: #832abf !important;
  background-color: #ffffff !important;
  border-bottom: 4px solid #832abf !important;
}
::v-deep .nav-link:hover {
  border: 0px solid #a15ccf !important;
  border-bottom: 4px solid #9450c2 !important;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #832abf;
  background-color: #832abf;
}
.badge-primary {
  color: #fff;
  background-color: #832abf;
}
@media (max-width: 767.98px) {
  .panel {
    position: relative;
    // top: 149px;
    top: 51px;
    height: calc(100vh - 0px);
    margin: 0;
  }
  .mobile-panel {
    top: 0px !important;
  }
  .card-chat {
    // height: calc(100% - 120px);
    height: calc(100% - 102px);
    background-color: #f6f6f6;
    border-radius: 0;
    // position: fixed;
    width: 100%;
    // top: 149px;
    // left: 0;
  }
  .contacts_body {
    position: relative;
    height: calc(100%);
    // height: calc(100% - 104px - 50px);
    width: 100%;
    top: 0;
    left: 0;
  }
}
</style>
