<template>
  <div>
    <div
      class="rounded shadow-sm p-3 bg-color-secondary my-1 d-flex"
      v-if="freeproduct.selected"
    >
      <div class="my-auto">
        <div
          class="product-image my-2"
          v-bind:style="{
            'background-image': 'url(' + freeproduct.imageUrl + ')'
          }"
        ></div>
      </div>
      <div class="ml-2 w-100">
        <div class="d-flex">
          <div class="line-clamp">{{ freeproduct.name }}</div>
          <div class="ml-auto">{{ freeproduct.quantity }}</div>
        </div>

        <div class="f-size-14 text-secondary">
          {{ freeproduct.barcode }}
        </div>

        <div class="d-flex">
          <div class="f-size-14 text-secondary">
            {{ freeproduct.price | numeral("0,0.00") }} x
            {{ freeproduct.quantity }}
          </div>
          <div class="ml-auto f-size-14 ">
            ฟรี
          </div>
        </div>
      </div>
      <!-- <b-row
        class="rounded shadow-sm p-3 bg-color-secondary my-1"
        v-if="freeproduct.selected"
      >
        <b-col cols="12" class="pl-4">
          <b-row class="">
            <b-col cols="7" xl="9" md="8" class="p-0 pl-1">
              <div class="mr-2">{{ freeproduct.name }}</div>
              <div class="mr-2 text-color-grey">{{ freeproduct.barcode }}</div>
              <div class="text-color-grey d-block d-sm-none">
                {{ freeproduct.price | numeral("0,0.00") }} x
                {{ freeproduct.quantity | numeral("0,0.00") }}
              </div>
            </b-col>
            <b-col
              cols="5"
              xl="3"
              md="4"
              class="d-flex align-items-end flex-column"
              style="padding-left:0px"
            >
              <div>
                <span class="mx-2 text-big" style="vertical-align:middle">{{
                  freeproduct.quantity
                }}</span>
              </div>
              <div class="text-right mt-auto d-block d-sm-none">
                {{ freeproduct.total | numeral("0,0.00") }}
              </div>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col class="d-none d-sm-block p-0 pl-1">
              <div class="text-color-grey">
                ราคา {{ freeproduct.price | numeral("0,0.00") }} x
                {{ freeproduct.quantity | numeral("0,0.00") }}
              </div></b-col
            >
            <b-col class="d-none d-sm-block"
              ><div class="text-right">
                {{ freeproduct.total | numeral("0,0.00") }}
              </div></b-col
            >
          </b-row>
        </b-col>
      </b-row> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    freeproduct: Object
  },
  methods: {
    addCommas(x = 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
    }
  }
};
</script>

<style lang="scss" scoped>
.product-image {
  width: 80px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
