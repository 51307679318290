<template>
  <div>
    <div class="panel-address">
      <b-row>
        <b-col md="6">
          <InputText
            v-model="form.telephone"
            textFloat="หมายเลขโทรศัพท์"
            placeholder="หมายเลขโทรศัพท์"
            type="text"
            name="telephone"
            :maxlength="10"
            size="lg"
            isRequired
            @onkeypress="isNumber($event)"
            :isValidate="$v.form.telephone.$error"
            :v="$v.form.telephone"
            class="f-regular"
          />
        </b-col>
        <b-col md="6">
          <InputText
            v-model="form.firstname"
            textFloat="ชื่อ"
            placeholder="ชื่อ"
            type="text"
            name="nameOrCompanyName"
            size="lg"
            isRequired
            :isValidate="$v.form.firstname.$error"
            :v="$v.form.firstname"
            class="f-regular"
          />
        </b-col>
        <b-col md="6">
          <InputText
            v-model="form.lastname"
            textFloat="นามสกุล"
            placeholder="นามสกุล"
            type="text"
            name="lastname"
            size="lg"
            isRequired
            :isValidate="$v.form.lastname.$error"
            :v="$v.form.lastname"
            class="f-regular"
          />
        </b-col>

        <b-col class="col-md-6 col-12">
          <InputText
            type="text"
            textFloat="อีเมล์"
            placeholder="อีเมล์"
            v-model="form.email"
            size="lg"
            class="f-regular"
            :isValidate="$v.form.email.$error"
            :v="$v.form.email"
          />
        </b-col>
      </b-row>
      <div class="pb-2">
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-button
              class="bg-color-primary text-white text-center"
              @click="updateUserInfo"
              >แก้ไขข้อมูล</b-button
            >
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required, helpers, email, minLength } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);
export default {
  name: "ChatOrderProfileDetailForm",
  components: {
    InputText
  },
  props: {
    userInfo: {
      required: true,
      type: Object
    },
    getUserInfo: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      form: {
        telephone: null,
        firstname: null,
        lastname: null,
        email: null,
        socialMediaId: 0,
        socialMediaName: null,
        socialReferenceId: null,
        socialSourceId: 0
      }
    };
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateUserInfo: async function() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$store.dispatch("setIsLoading", true);
      await this.$axios
        .patch(`${this.$baseUrl}/api/user/guid`, this.form)
        .then(response => response.data)
        .then(res => {
          this.$store.dispatch("setIsLoading", false);
          if (res.result) {
            this.$store.commit("setMessageGlobalAlert", "สำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
            this.getUserInfo();
          } else {
            this.$store.commit("setMessageGlobalAlertError", res.message);
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log("error", error);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
          this.$store.dispatch("setIsLoading", false);
        });
    }
  },
  created: async function() {
    this.form = { ...this.userInfo };
  },
  validations() {
    return {
      form: {
        firstname: { required, alpha },
        lastname: { required, alpha },
        email: { email },
        telephone: { required, minLength: minLength(9) }
      }
    };
  }
};
</script>
