<template>
  <div>
    <div id="sidebarRightSettingTextReply" class="sidepanel">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav()">×</a>
      <span class="title-side">เพิ่มข้อความตอบกลับ</span>
      <div class="border-top pt-2">
        <div class="px-3">
          <b-form class="mb-2">
            <div>
              <label for="tagTitle" class="mb-0">Title :</label>
              <b-form-input
                v-model="formTag.title"
                id="tagTitle"
                placeholder="Title"
                class="form-control"
              ></b-form-input>
            </div>
            <div class="mt-2">
              <label for="tagShortkeys" class="mb-0">Short keys :</label>
              <b-form-input
                v-model="formTag.shortkeys"
                id="tagShortkeys"
                placeholder="Short keys"
                class="form-control"
              ></b-form-input>
            </div>
            <div class="mt-2">
              <label for="tagTextReply" class="mb-0">Text reply :</label>
              <b-form-input
                v-model="formTag.textReply"
                id="tagTextReply"
                placeholder="Text reply"
                class="form-control"
              ></b-form-input>
            </div>
          </b-form>
          <div class="pb-2 w-100">
            <b-overlay
              :show="isLoadingSaveTag"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block w-100"
            >
              <b-button
                @click="addTag()"
                variant="secondary"
                class="w-100"
                :disabled="
                  formTag.title == '' ||
                    formTag.shortkeys == '' ||
                    formTag.textReply == '' ||
                    isLoadingSaveTag
                "
                >เพิ่ม
              </b-button>
            </b-overlay>
          </div>
        </div>
        <!-- <b-form-tags
          v-show="$store.getters.getChatTextReplyList.length > 0"
          no-outer-focus
          class="mb-2"
        > -->
        <div class="mb-2 px-3">
          <div
            v-for="(tag, idx) in $store.getters.getChatTextReplyList"
            :key="tag.idx"
            class="mr-1 px-2 mb-2 py-2 keyword-box"
          >
            <div class="wrap-div-action-tag">
              <div class="div-action-tag">
                <b-button
                  v-if="!tagOpenEdit.includes(idx)"
                  variant="link"
                  @click="toggleEditTag(1, idx, tag)"
                  class="p-0"
                  >แก้ไข</b-button
                >
                <b-button
                  variant="link"
                  @click="removeTag(tag, idx)"
                  class="p-0 ml-2"
                  >ลบ</b-button
                >
              </div>
            </div>
            <div v-if="!tagOpenEdit.includes(idx)">
              <div class="d-flex flex-row">
                <div>
                  <div class="div-textTitle">{{ tag.title }}</div>
                  <div class="div-textShortkeys">/{{ tag.shortkeys }}</div>
                </div>
              </div>
              <div class="mt-1 div-textReply text-wrap">
                {{ tag.textReply }}
              </div>
            </div>
            <div v-if="tagOpenEdit.includes(idx)">
              <b-form class="mb-0">
                <div>
                  <label for="tagTitle" class="mb-0">Title :</label>
                  <b-form-input
                    v-model="formEditTag.title"
                    :id="'tagTitleEdit'"
                    placeholder="Title"
                    class="form-control"
                  ></b-form-input>
                </div>
                <div class="mt-2">
                  <label for="tagShortkeys" class="mb-0">Short keys :</label>
                  <b-form-input
                    v-model="formEditTag.shortkeys"
                    :id="'tagShortkeysEdit'"
                    placeholder="Short keys"
                    class="form-control"
                  ></b-form-input>
                </div>
                <div class="mt-2">
                  <label for="tagTextReply" class="mb-0">Text reply :</label>
                  <b-form-input
                    v-model="formEditTag.textReply"
                    :id="'tagTextReplyEdit'"
                    placeholder="Text reply"
                    class="form-control"
                  ></b-form-input>
                </div>
                <div class="pt-2 d-flex w-100">
                  <div class="ml-auto">
                    <b-button
                      @click="toggleEditTag(2, idx, tag)"
                      variant="secondary"
                      size="sm"
                      class="btn-cancel-edit"
                      >ยกเลิก</b-button
                    >
                    <b-button
                      @click="submitEditTag()"
                      variant="secondary"
                      size="sm"
                      class="ml-1"
                      :disabled="
                        formEditTag.title == '' ||
                          formEditTag.shortkeys == '' ||
                          formEditTag.textReply == '' ||
                          isLoadingSaveTag
                      "
                      >บันทึก</b-button
                    >
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
        <!-- </b-form-tags> -->
        <div
          v-if="
            $store.getters.getChatTextReplyList.length == 0 &&
              !$store.state.isLoadingChatTextReply
          "
          class="text-center w-100 py-4"
        >
          <span class="">ไม่มีข้อความตอบกลับ</span>
        </div>
        <div
          v-if="
            $store.getters.getChatTextReplyList.length == 0 &&
              $store.state.isLoadingChatTextReply
          "
          class="text-center w-100 py-4"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatSideBarSetReply",
  data() {
    return {
      formTag: {
        title: "",
        shortkeys: "",
        textReply: ""
      },
      formEditTag: {
        id: "",
        title: "",
        shortkeys: "",
        textReply: ""
      },
      isLoadingSaveTag: false,
      tagOpenEdit: []
    };
  },
  methods: {
    openNav() {
      document.getElementById("sidebarRightSettingTextReply").style.width =
        this.$route.query.isMobile == "true" ? "100%" : "400px";
    },
    closeNav() {
      document.getElementById("sidebarRightSettingTextReply").style.width = "0";
    },
    addTag() {
      this.isLoadingSaveTag = true;
      this.$axios
        .post(this.$chatBaseUrl + `/reply/save`, {
          userGUID: this.$store.state.chatAdminProfile.externalId,
          title: this.formTag.title,
          shortkeys: this.formTag.shortkeys,
          textReply: this.formTag.textReply,
          updatedTime: "2021-12-29T19:50:27.602Z",
          createdTime: "2021-12-29T19:50:27.602Z"
        })
        .then(response => {
          this.isLoadingSaveTag = false;
          this.$store.dispatch("getChatTextReplyAdmin");
          if (response.data.result == 1) {
            this.formTag.title = "";
            this.formTag.shortkeys = "";
            this.formTag.textReply = "";
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          this.isLoadingSaveTag = false;
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    removeTag(val, idx) {
      this.$store.commit("SPLICE_TEXT_REPLY_LIST", { idx: idx });
      this.$axios
        .delete(this.$chatBaseUrl + `/reply/${val.id}`)
        .then(response => {
          if (!response.data.result == 1) {
            this.$store.dispatch("getChatTextReplyAdmin");
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    toggleEditTag(flag, idx, tagVal) {
      const { id, title, shortkeys, textReply } = tagVal;
      this.tagOpenEdit = [];
      this.formEditTag = {
        id: "",
        title: "",
        shortkeys: "",
        textReply: ""
      };
      if (flag == 1) {
        this.formEditTag = {
          id: id,
          title: title,
          shortkeys: shortkeys,
          textReply: textReply
        };
        this.tagOpenEdit.push(idx);
      }
    },
    submitEditTag() {
      this.isLoadingSaveTag = true;
      this.$axios
        .post(this.$chatBaseUrl + `/reply/save`, {
          userGUID: this.$store.state.chatAdminProfile.externalId,
          id: this.formEditTag.id,
          title: this.formEditTag.title,
          shortkeys: this.formEditTag.shortkeys,
          textReply: this.formEditTag.textReply,
          updatedTime: "2021-12-29T19:50:27.602Z",
          createdTime: "2021-12-29T19:50:27.602Z"
        })
        .then(response => {
          this.isLoadingSaveTag = false;
          this.$store.dispatch("getChatTextReplyAdmin");
          if (response.data.result == 1) {
            this.toggleEditTag(2, 0, {});
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          this.isLoadingSaveTag = false;
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    }
  }
  // created() {
  //   this.$store.dispatch("getChatTextReplyAdmin");
  // }
};
</script>

<style lang="scss" scoped>
.sidepanel {
  width: 0;
  position: fixed;
  z-index: 9999;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 52px;
  color: #707070;
  border-left: 1px solid #b3afaf6e;
}

.sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #707070;
  display: block;
  transition: 0.3s;
}

.sidepanel .closebtn {
  position: absolute;
  top: -9px;
  right: 10px;
  font-size: 34px;
}
.sidepanel .title-side {
  position: absolute;
  top: 15px;
  left: 14px;
  font-size: 16px;
}

::v-deep button.close {
  margin-left: 10px;
  margin-top: -8px;
}
::v-deep button.close:focus,
::v-deep button.close:focus-within {
  outline: 0px dotted;
}
::v-deep .b-form-tag-content {
  margin: auto 0;
}
::v-deep .btn-secondary {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
.div-textTitle {
  font-size: 16px;
  font-weight: 400;
  max-width: 14rem;
  word-wrap: break-word;
  line-height: 1.2;
  margin-bottom: 4px;
}
.div-textShortkeys {
  font-size: 13px;
  font-weight: 400;
  max-width: 20rem;
  word-wrap: break-word;
  line-height: 1.2;
}
.div-textReply {
  font-size: 12px;
  font-weight: 400;
}

.keyword-box {
  background-color: #e2dff3;
  color: #2c3e50;
  white-space: break-spaces;
}
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
  border: 0.25em solid #832abf;
  border-right-color: transparent;
}
.wrap-div-action-tag {
  position: relative;
}
.div-action-tag {
  position: absolute;
  top: 0;
  right: 2px;
}
.btn-cancel-edit {
  color: #832abf !important;
  background-color: #ffffff !important;
  border: 1px solid #9450c2;
}
</style>
