<template>
  <div
    :class="['panel', $route.query.isMobile == 'true' ? 'mobile-panel' : '']"
  >
    <div v-if="isError" class="reload-chat">
      <div class="text-center">
        <p>Network error!!</p>
        <button class="btn btn-primary btn-sm" @click="prepareData">
          Reload
        </button>
      </div>
    </div>
    <div v-if="!isError && !isShowProfileOrder" class="h-100">
      <div class="loading-prepare" v-if="isPrepare">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else class="card-box">
        <div v-if="!isShowBoth" class="card-box-header msg_head">
          <div class="d-flex bd-highlight">
            <div
              v-if="$route.query.isChatBox != 'true'"
              class="img_back mr-3"
              @click="$emit('show-list-chat')"
            >
              <font-awesome-icon
                :icon="['fas', 'chevron-left']"
                class="img_back_icon"
              />
            </div>
            <div class="img_cont">
              <div v-if="otherInfo.imageUrl" class="rounded-circle user_img">
                <div
                  class="rounded-circle user_img_display"
                  v-bind:style="{
                    backgroundImage: 'url(' + otherInfo.imageUrl + ')',
                  }"
                ></div>
              </div>
              <font-awesome-icon
                v-else
                :icon="['fas', 'user']"
                class="rounded-circle user_img"
              />
              <!-- <span class="online_icon"></span> -->
            </div>
            <div class="user_info">
              <span>{{ otherInfo.displayname }}</span>
              <!-- <span>{{ otherInfo.socialName || "" }}</span> -->
            </div>
            <div class="ml-auto">
              <b-button
                variant="link"
                class="pr-0"
                @click="handleOpenProfileSideBar(true)"
                v-if="isShowProfileTab"
                >Order</b-button
              >
            </div>
          </div>
        </div>
        <div v-if="isNoConversation" class="panel-wellcome-chat">
          <div class="text-center">
            <div class="wrap-other-chat-img mb-2 mx-auto">
              <div
                class="other-chat-img rounded-circle"
                v-bind:style="{
                  backgroundImage: 'url(' + otherInfo.photoUrl + ')',
                }"
              ></div>
            </div>
            <p class="mb-0">พูดคุยกับ {{ otherInfo.name }}</p>
          </div>
        </div>
        <div v-else class="wrpa-msg_card_body">
          <div
            v-if="isLoadingConversation && conversation.data !== null"
            class="loading-message w-100 text-center"
          >
            <div class="spinner-border text-secondary bg-none" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            v-show="conversation.data && conversation.data !== null"
            :class="[
              'msg_card_body',
              $route.query.isMobile == 'true'
                ? 'msg_card_body-is-mobile-app'
                : '',
            ]"
            id="chatMsgCardBody"
          >
            <ChatBoxMessageList
              :messageList="conversation.data.slice().reverse()"
              :meProfile="meProfile"
              :otherInfo="otherInfo"
            />
            <!-- <ChatBoxMessageList
              :messageList="dummyMessageList"
              :meProfile="meProfile"
              :otherInfo="otherInfo"
            /> -->
          </div>
        </div>
        <div class="panel-wrap-card-box-footer px-3 pt-2">
          <div class="wrap-card-box-footer">
            <div v-if="isShowTranslate" class="pb-2">
              <ChatBoxTranslateFlex
                ref="ChatBoxTranslateFlex"
                :text="textInput"
                :isLoadingTranslate="isLoadingTranslate"
                :textTranslated="textTranslated"
              />
            </div>
            <b-list-group
              v-if="!showSetReply && !isShowTranslate"
              class="list-reply"
            >
              <b-list-group-item
                class="list-item-reply"
                v-for="(choice, idx) in choiceReply"
                :key="choice.id"
              >
                <div class="d-flex flex-row">
                  <div
                    class="d-flex flex-row w-75"
                    @click="clickSelectTextReply(choice)"
                  >
                    <div class="w-25 reply-key">
                      <p class="font-weight-bold">/{{ choice.shortkeys }}</p>
                    </div>
                    <div class="w-50 pl-2">
                      <p class="mb-0">{{ choice.title }}</p>
                      <p class="text-black-50">{{ choice.textReply }}</p>
                    </div>
                  </div>

                  <div class="w-25 d-flex justify-content-end">
                    <a
                      class="w-50 d-flex justify-content-end menu-reply"
                      @click="handleShowEditReply(choice)"
                      >แก้ไข</a
                    >
                    <a
                      class="w-50 d-flex justify-content-end menu-reply"
                      @click="delReply(choice.id, idx)"
                      >ลบ</a
                    >
                  </div>
                </div></b-list-group-item
              >
            </b-list-group>
            <div class="edit-reply" v-if="showSetReply && !isShowTranslate">
              <div class="pt-2 pl-2 pr-2">
                <font-awesome-icon
                  icon="chevron-left"
                  class="back-edit-reply"
                  @click="handleShowEditReply"
                />
                <span class="ml-1"> {{ formEditTag.title }} </span>
              </div>
              <hr class="text-muted" />
              <div>
                <textarea
                  id="EditReplyTextArea"
                  name="EditReplyTextArea"
                  class="form-control type_msg"
                  placeholder="Text reply"
                  v-model="formEditTag.textReply"
                  rows="4"
                ></textarea>
              </div>
              <div class="pt-2 pl-2 pr-2 w-100 d-flex justify-content-end">
                <b-button
                  @click="editReply()"
                  :disabled="formEditTag.textReply == ''"
                  >แก้ไข
                </b-button>
              </div>
            </div>
            <ChatBoxNote
              v-if="isShowNote"
              :userGUID="otherInfo.externalId"
              :chatroomId="formatIdConversation"
              :handleLoadError="handleLoadError"
              @handle-show-note="(val) => (isShowNote = val)"
            />
            <div class="input-group d-flex">
              <textarea
                v-show="!isShowNote"
                id="chatInboxTextArea"
                name="chatInboxTextArea"
                class="form-control type_msg"
                placeholder="Message"
                v-model="textInput"
                @keypress="handlePushMessage"
                @keyup="resizeTextArea"
                @keydown="resizeTextArea"
                @focus="readMessage"
                rows="3"
                @blur="blurMessage"
              ></textarea>
            </div>
            <div class="px-3 py-2 d-flex justify-content-between border-top">
              <!-- <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch1"
                  v-model="adminMode"
                  @change="handleChangeMode"
                />
                <label class="custom-control-label" for="customSwitch1">
                  {{ adminMode ? "Admin Mode" : "Bot Mode" }}</label
                >
              </div> -->
              <div class="">
                <b-button
                  class="send_btn btn-sm"
                  v-if="adminMode"
                  @click="
                    adminMode = false;
                    handleChangeMode();
                  "
                >
                  End Chat
                </b-button>
              </div>
              <div class="d-flex justify-content-end">
                <div class="pr-2">
                  <span
                    class="input-group-text attach_btn f-size-16"
                    @click="isShowNote = !isShowNote"
                  >
                    <font-awesome-icon icon="clipboard-list" class="" />
                  </span>
                </div>
                <div class="pr-2">
                  <span
                    class="input-group-text attach_btn f-size-16"
                    @click="toggleSideBarSetTextReply"
                  >
                    <font-awesome-icon icon="comment-dots" class="" />
                  </span>
                </div>
                <!-- upload file -->
                <div class="pr-2">
                  <span
                    class="input-group-text attach_btn"
                    @click="handleBrowseFile"
                  >
                    <font-awesome-icon icon="file-upload" class="" />
                  </span>
                  <input
                    type="file"
                    name="chatBoxInputFile"
                    id="chatBoxInputFile"
                    style="display:none;"
                    accept=".txt, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, video/mp4, video/x-m4v, video/*,"
                    @change="preparePushFile"
                    multiple
                  />
                </div>
                <!-- upload img -->
                <div class="">
                  <span
                    class="input-group-text attach_btn"
                    @click="handleBrowseImg"
                  >
                    <font-awesome-icon :icon="['fas', 'image']" class="" />
                  </span>
                  <input
                    type="file"
                    name="chatBoxInputImg"
                    id="chatBoxInputImg"
                    style="display:none;"
                    accept="image/png, image/jpeg"
                    @change="preparePushFile"
                    multiple
                  />
                </div>
                <b-button
                  class="send_btn btn-sm ml-2"
                  @click="preparePushMessage"
                >
                  Send
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-sidebar
      v-if="!isShowBoth"
      id="sidebarRightChatOrder"
      right
      no-header
      class="h-100"
    >
      <!-- <div class="panel-close-profile-order pb-1 pt-2 px-3">
        <span @click="handleOpenProfileSideBar(false)" class="cursor-pointer">
          <font-awesome-icon :icon="['fas', 'times']" class="" />
          ปิด
        </span>
      </div> -->
      <ChatOrderPanel
        v-if="isShowProfileTab && isShowProfileOrder && !isShowBoth"
        :userChatInfo="userChatInfo"
        class="bg-white ChatOrderPanel"
      />
    </b-sidebar>
  </div>
</template>

<script>
import ChatBoxMessageList from "@/components/chat/chatBox/ChatBoxMessageList.vue";
import ChatOrderPanel from "@/components/chat/chatOrder/ChatOrderPanel";
import ChatBoxNote from "@/components/chat/chatBox/ChatBoxNote";
import ChatBoxTranslateFlex from "@/components/chat/chatBox/ChatBoxTranslateFlex";

export default {
  name: "ChatBox",
  components: {
    ChatBoxMessageList,
    ChatOrderPanel,
    ChatBoxNote,
    ChatBoxTranslateFlex,
  },
  props: {
    meProfile: {
      required: true,
      type: Object,
    },
    // otherProfile: {
    //   required: true,
    //   type: Object
    // },
    isFromList: {
      required: false,
      type: Boolean,
    },
    isShowBoth: {
      required: false,
      type: Boolean,
    },
    formatIdConversation: {
      required: false,
      type: String,
    },
    channelId: {
      required: false,
      // type: Any
    },
    userId: {
      required: false,
      type: String,
    },
    conversationInfo: {
      required: false,
      type: Object,
    },
    userChatInfo: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      isShowProfileOrder: false,
      isNoConversation: false,
      isError: false,
      isPrepare: true,
      isFirstLoad: true,
      isLoadingConversation: false,
      isWaitingLoadingCon: false,
      coundDownWaitingLoadingCon: 5,
      textInput: "",
      // formatIdConversation: "",
      otherInfo: {
        targetApp: null,
        name: "",
        email: [],
        photoUrl: " ",
        role: "",
        phone: null,
        welcomeMessage: "",
        //new
        externalId: "",
        isTranslate: "",
      },
      filterConversation: {
        brandId: this.$route.query.brandID,
        limit: 20,
      },
      conversation: {
        data: [],
      },
      isBusyCon: false,
      heightDivChatMsgCardBody: 0,
      dummyMessageList: [],
      adminMode: this.conversationInfo.mode === 1 ? true : false,
      choiceReply: [],
      showSetReply: false,
      formEditTag: {
        id: "",
        title: "",
        shortkeys: "",
        textReply: "",
      },
      isShowNote: false,
      isShowTranslate: false,
      textTranslated: "",
      isLoadingTranslate: false,
      timeCallTranslate: null,
      isShowProfileTab: true,
    };
  },
  methods: {
    prepareData: async function() {
      this.isFirstLoad = true;
      this.isPrepare = true;
      this.isError = false;
      this.$emit("set-show-title", false);
      // this.formatIdConversation = `${this.meProfile.chatId}___${this.otherProfile.chatId}`;
      // if (this.isFromList) {
      this.getOther();
      // } else {
      //   this.updateOther();
      // }
      this.getConversation(true, false);
      // this.readMessage();
    },
    getOther() {
      this.$emit("set-selected-user-chat", {
        externalId: "",
      });
      fetch(`${this.$chatBaseUrl}/user/${this.userId}`, {
        method: "GET",
        headers: new Headers(this.$headersChat),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.result) {
            this.otherInfo = res.detail;
            this.$emit("set-selected-user-chat", res.detail);
            this.$store.commit(
              "UPDATE_CHAT_ISTRANSLATE",
              this.otherInfo.isTranslate
            );
          } else {
            this.handleLoadError();
          }
        })
        .catch((error) => {
          this.handleLoadError();
          console.log("error", error);
        });
    },
    getConversation(isForceScrollToBottom, isInfiniteScroll) {
      if (this.isWaitingLoadingCon && this.coundDownWaitingLoadingCon > 0) {
        this.coundDownWaitingLoadingCon--;
        return;
      }
      this.isWaitingLoadingCon = true;
      this.coundDownWaitingLoadingCon = 5;
      let startingAfter = "";
      if (isInfiniteScroll && this.conversation.data.length) {
        startingAfter = this.conversation.data[
          this.conversation.data.length - 1
        ].id;
      }
      fetch(
        `${this.$chatBaseUrl}/message/chatroom/${this.formatIdConversation}?start=${startingAfter}&length=${this.filterConversation.limit}`,
        {
          method: "GET",
          headers: new Headers(this.$headersChat),
          // body: JSON.stringify(body)
        }
      )
        .then((response) => {
          // if (response.status == 401) {
          //   this.$cookies.remove("kerry_session_token");
          //   this.$cookies.remove("kerry_username");
          //   this.$router.push({ path: "/login" });
          // }
          return response.json();
        })
        .then((result) => {
          this.isWaitingLoadingCon = false;
          if (result.result) {
            // this.adminMode = result.detail;
            // if (result.length > 0) {
            //   result.result = result.result.reverse();
            // }
            this.heightDivChatMsgCardBody = 0;
            if (
              result.detail == null ||
              (result.detail.length < 1 && this.isFirstLoad)
            ) {
              this.isFirstLoad = false;
              this.isPrepare = false;
              this.isNoConversation = true;
              return;
            }
            this.isNoConversation = false;
            if (isInfiniteScroll) {
              this.isLoadingConversation = false;
              let tempObjDiv = document.getElementById("chatMsgCardBody");
              this.heightDivChatMsgCardBody = tempObjDiv.scrollHeight;
            }
            result.detail.forEach((mess) => {
              if (this.dummyMessageList.length) {
                let indx = this.dummyMessageList.findIndex(
                  (temp) => mess.offlineId && mess.offlineId === temp.offlineId
                );
                if (indx >= 0) {
                  this.dummyMessageList.splice(indx, 1);
                }
              }
            });
            if (
              this.conversation.data.length &&
              result.detail &&
              !isInfiniteScroll
            ) {
              let tempResultData = result.detail;
              let tempConData = this.conversation.data;

              let idxCon = tempConData.findIndex(
                (itm) => itm.id === tempResultData[tempResultData.length - 1].id
              );
              tempConData.splice(0, idxCon + 1);
              this.conversation.data = tempResultData.concat(tempConData);
            } else {
              this.conversation.data = this.conversation.data.concat(
                result.detail
              );
            }
            if (isForceScrollToBottom && !this.isError) {
              setTimeout(() => {
                let objDiv = document.getElementById("chatMsgCardBody");
                objDiv.scrollTop = objDiv.scrollHeight;
              }, 100);
            }
            if (!this.isFirstLoad) {
              let objDiv2 = document.getElementById("chatMsgCardBody");
              setTimeout(() => {
                if (isInfiniteScroll) {
                  objDiv2.scrollTop =
                    objDiv2.scrollHeight - this.heightDivChatMsgCardBody;
                } else {
                  if (
                    objDiv2.scrollHeight -
                      objDiv2.scrollTop -
                      objDiv2.clientHeight <
                    150
                  ) {
                    objDiv2.scrollTop = objDiv2.scrollHeight;
                  }
                }
              }, 100);
            }
            if (this.isFirstLoad) {
              setTimeout(() => {
                document.getElementById("chatMsgCardBody").addEventListener(
                  "scroll",
                  async () => {
                    if (
                      document.getElementById("chatMsgCardBody").scrollTop ==
                        0 &&
                      !this.isBusyCon
                    ) {
                      this.isBusyCon = true;
                      this.isLoadingConversation = true;
                      this.heightDivChatMsgCardBody = 0;
                      this.getConversation(false, true);
                    }
                  },
                  false
                );
              }, 500);
              this.isPrepare = false;
              this.isFirstLoad = false;
            }
            this.$emit("handle-no-list-chat", false);
          } else {
            this.handleLoadError();
          }
          if (isInfiniteScroll) {
            if (result.detail.length > 0) {
              this.isBusyCon = false;
            } else {
              this.isBusyCon = true;
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.handleLoadError();
        });
    },
    handlePushMessage(e) {
      if (e.keyCode === 13 && !e.shiftKey && this.textInput.trim() != "/") {
        e.preventDefault();
        this.preparePushMessage();
      }
      //  else if (
      //   e.keyCode === 13 &&     !e.shiftKey      ) {
      //   // this.choiceReply.length > 0
      //   e.preventDefault();
      //   this.textInput = this.choiceReply[0].textReply;
      //   console.log(this.choiceReply[0])
      // }
    },
    preparePushMessage() {
      if (this.textInput.trim().length > 0 && !this.isLoadingTranslate) {
        let dataSend = {
          text: this.textInput,
          attachments: "",
        };
        if (this.isShowTranslate) {
          dataSend.text = `${dataSend.text}\n---------------\n${this.textTranslated}`;
          this.isShowTranslate = false;
          this.textTranslated = "";
        }
        this.textInput = "";
        this.sendMessage(dataSend, 1); // 1 = type text
      }
    },
    preparePushFile(e) {
      if (e.target.files.length) {
        let dataSend = {
          text: this.textInput,
          attachments: [],
        };

        var fileInput = document.getElementById("chatBoxInputFile").value;
        dataSend.text = this.getFileName(fileInput);

        e.target.files.forEach((file) => {
          if (file.size < 4194304) {
            //Max 4MB
            let readerFile = new FileReader();
            readerFile.readAsDataURL(file);
            readerFile.onload = () => {
              this.isSending = true;

              dataSend.attachments = readerFile.result;

              if (
                this.getExtension(file.name).toLowerCase() == "jpg" ||
                this.getExtension(file.name).toLowerCase() == "png" ||
                this.getExtension(file.name).toLowerCase() == "gif" ||
                this.getExtension(file.name).toLowerCase() == "jpeg"
              ) {
                this.sendMessage(dataSend, 4);
              } else {
                this.sendMessage(dataSend, 8);
              }
            };
          }
        });
      }
    },
    getFileName(fullPath) {
      var name = fullPath.split("\\");
      return name[name.length - 1];
    },
    getExtension(filename) {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    },
    sendMessage(dataSend, type) {
      let body = {
        messages: [
          {
            mode: 1,
            timeStamp: "",
            replyToken: null,
            prechatInfo: {
              SurveyScore: "1.0",
            },
            message: {
              messageType: type,
              messageId: "",
              text: dataSend.text,
              base64: dataSend.attachments,
              custom: {},
            },
            source: {
              userGUID: this.otherInfo.externalId,
              channelId: this.channelId,
            },
          },
        ],
      };
      if (!type) {
        return;
      }
      fetch(`${this.$chatBaseUrl}/message`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.result) {
            // this.getConversation(true, true);
          } else {
            this.handleLoadError();
          }
        })
        .catch((error) => {
          this.handleLoadError();
          console.log("error", error);
        });

      this.choiceReply = [];
      this.textInput = "";
    },
    handleBrowseImg() {
      document.getElementById("chatBoxInputImg").click();
      this.readMessage();
    },
    handleBrowseFile() {
      document.getElementById("chatBoxInputFile").click();
      this.readMessage();
    },
    readMessage() {
      this.choiceReply = [];
      if (this.textInput[0] === "/") {
        let keyword = {
          userGUID: this.$store.state.chatAdminProfile.externalId,
          Shortkeys: this.textInput.substring(1),
        };
        this.getTextReply(keyword);
      }
      fetch(`${this.$chatBaseUrl}/message/read/${this.formatIdConversation}`, {
        method: "GET",
        headers: new Headers(this.$headersChat),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.result) {
            this.$emit("set-read-conversation", this.formatIdConversation);
          } else {
            this.handleLoadError();
          }
        })
        .catch((error) => {
          this.handleLoadError();
          console.log("error", error);
        });
    },
    blurMessage() {
      setTimeout(() => {
        this.choiceReply = [];
      }, 250);
      if (this.showSetReply) {
        this.showSetReply = false;
      }
    },
    handleChangeMode() {
      let body = {
        mode: this.adminMode ? 1 : 2,
      };
      this.$store.dispatch("setIsLoading", true);
      fetch(
        `${this.$chatBaseUrl}/chatroom/${this.formatIdConversation}/change-mode`,
        {
          method: "POST",
          headers: new Headers(this.$headersChat),
          body: JSON.stringify(body),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          this.$store.dispatch("setIsLoading", false);
          if (result.result) {
            this.adminMode = body.mode === 1 ? true : false;
            // this.getConversation(true, false);
            // this.$emit("reload-detail-list", this.formatIdConversation);
          } else {
            this.handleLoadError();
          }
        })
        .catch((error) => {
          this.$store.dispatch("setIsLoading", false);
          this.handleLoadError();
          console.log("error", error);
        });
    },
    resizeTextArea(e) {
      if (
        e.keyCode === 13 &&
        this.textInput &&
        this.textInput[0] === "/" &&
        this.choiceReply.length
      ) {
        console.log(this.choiceReply.length);
        e.preventDefault();
        this.textInput = this.choiceReply[0].textReply;
        this.choiceReply = [];
        if (this.$store.state.isChatTranslate) {
          this.isShowTranslate = true;
          this.checkTranslate();
        }
      } else {
        if (!(e.keyCode === 13 && !e.shiftKey)) {
          if (this.textInput[0] === "/") {
            let keyword = {
              userGUID: this.$store.state.chatAdminProfile.externalId,
              Shortkeys: this.textInput.substring(1),
            };
            this.getTextReply(keyword);
          } else if (
            this.textInput.trim() != "" &&
            this.textInput[0] !== "/" &&
            this.$store.state.isChatTranslate
          ) {
            this.isShowTranslate = true;
            this.checkTranslate();
          } else {
            this.isShowTranslate = false;
          }
        }
        this.choiceReply = [];
        var textArea = document.getElementById("chatInboxTextArea");
        textArea.style.height = "auto";
        textArea.style.height = textArea.scrollHeight + "px";
      }
    },
    getTextReply(keyword) {
      this.choiceReply = [];
      if (!keyword) {
        keyword = {
          userGUID: this.$store.state.chatAdminProfile.externalId,
          Shortkeys: "",
        };
      }
      this.$axios
        .post(`${this.$chatBaseUrl}/reply/Find`, keyword)
        .then((result) => {
          if (result.data.result) {
            if (result.data.detail.length > 0) {
              this.choiceReply = result.data.detail;
            }
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              result.data.message
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    async delReply(id, index) {
      this.$store.commit("SPLICE_TEXT_REPLY_LIST", { idx: index });

      await this.$axios
        .delete(`${this.$chatBaseUrl}/reply/${id}`)
        .then((result) => {
          if (!result.data.result == 1) {
            this.$store.dispatch("getChatTextReplyAdmin");
            this.$store.commit(
              "setMessageGlobalAlertError",
              result.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
      this.getTextReply();
    },
    handleShowEditReply(choice) {
      if (choice) {
        this.formEditTag = {
          id: choice.id,
          shortkeys: choice.shortkeys,
          title: choice.title,
          textReply: choice.textReply,
        };
      }
      this.showSetReply = !this.showSetReply;
      this.getTextReply();
    },
    editReply() {
      this.$axios
        .post(this.$chatBaseUrl + `/reply/save`, {
          userGUID: this.$store.state.chatAdminProfile.externalId,
          id: this.formEditTag.id,
          title: this.formEditTag.title,
          shortkeys: this.formEditTag.shortkeys,
          textReply: this.formEditTag.textReply,
          updatedTime: "2021-12-29T19:50:27.602Z",
          createdTime: "2021-12-29T19:50:27.602Z",
        })
        .then((response) => {
          this.$store.dispatch("getChatTextReplyAdmin");
          if (response.data.result == 1) {
            this.formEditTag.id = "";
            this.formEditTag.title = "";
            this.formEditTag.shortkeys = "";
            this.formEditTag.textReply = "";
            this.handleShowEditReply();
            this.getTextReply();
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    handleLoadError() {
      this.isError = true;
      this.isBusyCon = true;
    },
    handleOpenProfileSideBar(flag) {
      document.getElementById("sidebarRightChatOrder").style.display = flag
        ? "block"
        : "none";
      this.isShowProfileOrder = flag;
      this.$store.commit("UPDATE_SHOW_CLOSE_BUTTON_CHATORDER", flag);
    },
    handlePullMessage() {
      let dumMes = this.$store.getters.getChatMessageDummy[0];
      let messSocketTemp = this.$store.getters.getChatMessageDummy;
      messSocketTemp.shift();
      this.$store.commit("UPDATE_CHAT_MESSAGE_DUMMY", messSocketTemp);
      if (dumMes.chatroomId === this.formatIdConversation) {
        if (dumMes.userType === "system") {
          this.adminMode = dumMes.mode == 1 ? true : false;
          // this.$emit("reload-detail-list", this.formatIdConversation);
        }
        this.conversation.data = [dumMes, ...this.conversation.data];
        // let dumIndx = this.dummyMessageList.findIndex(
        //   (tem) => dumMes.message.id === tem.offlineId
        // );
        // if (dumIndx >= 0) {
        //   this.dummyMessageList.splice(dumIndx, 1);
        // }
        setTimeout(() => {
          let objDiv = document.getElementById("chatMsgCardBody");
          objDiv.scrollTop = objDiv.scrollHeight;
          if (this.$store.getters.getChatMessageDummy.length > 0) {
            this.handlePullMessage();
          }
        }, 100);
      }
    },
    toggleSideBarSetTextReply() {
      let setWidth = this.$route.query.isMobile == "true" ? "100%" : "400px";
      if (
        document.getElementById("sidebarRightSettingTextReply").style.width ==
        setWidth
      ) {
        document.getElementById("sidebarRightSettingTextReply").style.width =
          "0";
      } else {
        document.getElementById(
          "sidebarRightSettingTextReply"
        ).style.width = setWidth;
      }
    },
    checkTranslate() {
      this.isLoadingTranslate = true;
      clearTimeout(this.timeCallTranslate);
      this.timeCallTranslate = setTimeout(() => {
        this.getTranslate();
      }, 3000);
    },
    getTranslate() {
      if (
        this.textInput &&
        this.textInput.trim() != "" &&
        this.textInput.trim() != "/"
      ) {
        this.isLoadingTranslate = true;
        this.textTranslated = "";
        fetch(`${this.$chatBaseUrl}/translate/${this.textInput}`, {
          method: "GET",
          headers: new Headers(this.$headersChat),
        })
          .then((response) => response.json())
          .then(async (result) => {
            this.isLoadingTranslate = false;
            if (result.result == 1) {
              this.textTranslated = result.detail;
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.isLoadingTranslate = false;
          });
      } else {
        this.isLoadingTranslate = false;
      }
    },
    clickSelectTextReply(choice) {
      this.textInput = choice.textReply;
      this.choiceReply = [];
      if (this.$store.state.isChatTranslate) {
        this.isShowTranslate = true;
        this.checkTranslate();
      }
    },
  },
  created() {
    this.prepareData();
    this.$store.commit("setShowProfileTab", false);
    this.isShowProfileTab = this.$store.getters.getIsShowProfileTab;
  },
  watch: {
    "$store.state.isLoadChatMessageList": function() {
      if (this.$store.state.isLoadChatMessageList) {
        this.handlePullMessage();
      }
    },
    "$store.state.isShowCloseButtonChatOrder": function() {
      if (!this.$store.state.isShowCloseButtonChatOrder) {
        this.handleOpenProfileSideBar(false);
        if (!this.isError) {
          setTimeout(() => {
            let objDiv = document.getElementById("chatMsgCardBody");
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 100);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  height: 100%;
  margin: 0;
}
.card-box {
  background-color: #f6f6f6;
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
}
.card-box-header {
  background-color: #fff;
  padding: 0.4rem 1rem;
  // font-family: "Kanit-Medium";
}
.wrpa-msg_card_body {
  position: relative;
  height: 100%;
  padding-left: 5px;
  display: contents;
}
.msg_card_body {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px 5px 3px 5px;
}
.msg_card_body::-webkit-scrollbar {
  width: 0.35em;
}
.msg_card_body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(177, 175, 175, 0.3);
}
.msg_card_body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: 1px solid #c1c1c1;
}
.wrap-card-box-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background: #fff;
}

.container {
  align-content: center;
}
.type_msg {
  background-color: inherit !important;
  color: #575757 !important;
  overflow: hidden;
  resize: none;
  padding-left: 15px;
  padding-right: 15px;
}
.type_msg:focus {
  box-shadow: rgba(0, 0, 0, 0.164) !important;
  outline: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.164) !important;
}
.type_msg::placeholder {
  color: #dbdbdb;
}
.attach_btn {
  border-radius: 22px !important;
  background-color: inherit !important;
  border: 0 !important;
  // color: #f3591f !important;
  cursor: pointer;
}
.send_btn {
  background-color: inherit !important;
  border: 0 !important;
  // color: #f3591f !important;
  // cursor: pointer;
}
.user_img {
  height: 40px;
  width: 40px;
  border: 1.5px solid #dbdbdb;
}
.user_img_display {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.img_back {
  position: relative;
  height: 44px;
  width: auto;
}
.img_back_icon {
  height: 100%;
  width: 15px;
  // color: #f3591f;
}
.img_cont {
  position: relative;
  height: 40px;
  width: 40px;
  margin-right: 15px;
}
.img_cont img {
  background-color: #fff;
}
.img_cont svg {
  padding: 5px;
}
.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
}
.user_info span {
  font-size: 16px;
  color: rgb(43, 43, 43);
  font-weight: bold;
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_head {
  position: relative;
  border-bottom: 1px solid #e6e7eb;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-radius: 22px;
}
.form-control {
  border: 0 solid !important;
}
.reload-chat,
.loading-prepare {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.loading-message {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: none;
}
.spinner-border {
  width: 20px;
  height: 20px;
}
.panel-wellcome-chat {
  padding-top: 20%;
  align-items: center;
  justify-content: center;
  // height: 75vh;
  height: 100%;
  width: 100%;
}
.panel-wellcome-chat p {
  color: rgb(43, 43, 43);
}
.wrap-other-chat-img {
  height: 100px;
  width: 100px;
}
.other-chat-img {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

::v-deep .b-sidebar-body,
::v-deep .b-sidebar {
  height: 100%;
}

.panel-close-profile-order {
  position: fixed;
  top: 2px;
  right: 5px;
  z-index: 1021;
}
.list-reply {
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  border-radius: 0rem;
}
.edit-reply {
  height: 190px;
  border-radius: 0rem;
  border: 1px solid rgba(0, 0, 21, 0.125);
}
.list-item-reply:hover {
  background-color: #ebedef;
  cursor: pointer;
}
.menu-reply {
  font-size: 12px;
}
.menu-reply:hover {
  cursor: pointer;
  text-decoration: underline;
}
.back-edit-reply:hover {
  cursor: pointer;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0rem;
}
.reply-key {
  word-wrap: break-word;
}
.f-size-16 {
  font-size: 16px;
}

@media (max-width: 767.98px) {
  .panel {
    position: relative;
    top: 51px;
    height: calc(100vh - 51px) !important;
  }
  .mobile-panel {
    top: 0px !important;
  }
  .card-box-header {
    padding: 0.4rem 1.25rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .img_back {
    height: 30px;
  }
  .user_img {
    height: 30px;
    width: 30px;
  }
  .img_cont {
    height: 30px;
    width: 30px;
  }
  .user_info span {
    font-size: 14px;
  }
  .wrpa-msg_card_body {
    top: 104px;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
  }
  .msg_card_body {
    position: relative;
    height: calc(100% - 104px - 44px - 30px);
    padding: 10px 5px 3px 5px;
  }
  .msg_card_body-is-mobile-app {
    height: calc(100% - 104px - 44px + 16px) !important;
  }
  .panel-wrap-card-box-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  .wrap-card-box-footer {
    position: relative;
    width: 100% !important;
    z-index: 1020;
    background-color: #fff;
  }

  .ChatOrderPanel {
    // height: 100vh;
    top: 0px;
    // height: calc(100vh - 51px) !important;
  }
}
</style>
