<template>
  <div>
    <div class="content-page">
      <!-- <Header v-if="$route.path !== '/login'" /> -->
      <ChatPanel
        :isShowBoth="screenSize.width > 767 ? true : false"
        @set-loading="(val) => (isLoading = val)"
      />
      <ModalPanelGloabal />
      <b-modal
        v-model="isShowModalOverLay"
        no-fade
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <img
            src="@/assets/images/loading.svg"
            alt="loading"
            class="my-2 loading-icon"
          />
          <div class="textc-primary">กรุณารอสักครู่</div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ChatPanel from "@/components/chat/ChatPanel";
// import Header from "@/views/Layouts/Header.vue";
import ModalPanelGloabal from "@/components/modal/alert/ModalPanelGloabal";
import { mapGetters } from "vuex";
export default {
  name: "chat-index",
  components: {
    // Header,
    ChatPanel,
    ModalPanelGloabal,
  },
  data() {
    return {
      isLoading: true,
      profile: {
        chatId: "",
        name: "",
        email: "",
        photoUrl: "",
      },
      screenSize: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      isShowModalOverLay: "getIsLoading",
    }),
  },
  methods: {
    handleGetSocialList() {
      fetch(`${this.$chatBaseUrl}/brand/social`, {
        method: "GET",
        headers: new Headers(this.$headersChat),
      })
        .then((response) => response.json())
        .then(async (result) => {
          if (result.result == 1) {
            this.$store.commit("UPDATE_CHAT_SOCIAL_LIST", result.detail);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleResize() {
      this.screenSize.width = window.innerWidth;
      this.screenSize.height = window.innerHeight;
    },
  },
  mounted() {},
  created: async function() {
    if (!this.$cookies.isKey("kerry-session-token")) {
      // this.$router.push({ path: "/" });
      window.location.href = this.$backofficeUrl + "/login";
    }
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    // this.$isLoading = true;
    // this.getAdminProfile();
    await this.$store.dispatch("getChatAdminProfile");
    this.$store.commit("setUsername", this.$cookies.get("kerry-username"));

    this.handleGetSocialList();
    setInterval(() => {
      if (!this.$store.state.isSocketOpen)
        this.$store.dispatch("handleChatSocket", {
          brandID: this.$route.query.brandID,
        });
    }, 2000);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.content-page {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  height: calc(100vh - 58px);
}

@media (max-width: 767.98px) {
  .content-page {
    background-color: white;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1010;
  }
}
</style>
