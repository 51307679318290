<template>
  <div>
    <!-- img type 2 -->
    <div class="img_upload" v-if="content.messageType == 2">
      <div
        class="img_upload-display"
        v-bind:style="{
          backgroundImage: 'url(' + content.message + ')',
        }"
      ></div>
    </div>
    <!-- file type 3 -->
    <div v-if="content.messageType == 3">
      <p
        class="item-file border-res-msg"
        @click="handleOpenTab(content.message)"
      >
        <font-awesome-icon icon="file-download" class="icon-file-download" />
        {{ showFileName(content.message) }}
      </p>
    </div>

    <div v-else-if="content.custom">
      <hr v-if="content.messageType == 3" />
      <span>{{ content.message }}</span>
      <hr v-if="content.custom.button && content.message" />
      <button
        v-for="(btn, j) in content.custom.button"
        type="button"
        class="btn btn-light w-100 mb-1 font-btn"
        :key="j"
      >
        <span class="font-btn">{{ btn.label }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    con: {
      required: false,
      type: Object,
    },
  },
  created() {
    this.content = this.con;
  },
  methods: {
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    showFileName(url) {
      let arrUrl = url.split("/");
      return arrUrl[arrUrl.length-1];
    }
  },
  data() {
    return {
      content: {},
    };
  },
};
</script>

<style lang="scss" scoped>
div .img_upload {
  margin-right: auto;
  height: auto;
  max-height: 20rem;
  width: 100%;
  max-width: 30rem;
}
div .img_upload .img_upload-display {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  background-color: inherit !important;
}
.font-btn {
  color: #9a161f;
  font-weight: bold;
}
.item-file {
  margin-bottom: 0;
  text-decoration: underline;
}
.item-file:hover {
  cursor: pointer;
}
.icon-file-download {
  height: 1rem !important;
  width: auto !important;
}
</style>
