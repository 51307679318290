<template>
  <div class="rounded shadow-sm p-3 bg-color-secondary d-flex">
    <div class="my-auto">
      <img :src="product.imageUrl" class="product-image" />
    </div>
    <div class="ml-2 w-100">
      <div class="d-flex">
        <div class="line-clamp">{{ product.name }}</div>
        <div v-if="isAction" class="d-flex ml-auto">
          <div class="">
            <font-awesome-icon
              v-if="orderStatus !== 1"
              class="text-color-secondary"
              icon="minus-circle"
              size="lg"
            />
            <font-awesome-icon
              v-if="orderStatus === 1"
              @click="removeProductQuantity(product)"
              icon="minus-circle"
              size="lg"
            />
          </div>
          <div class="mx-2 f-size-16 ">{{ product.quantity }}</div>
          <div class="">
            <font-awesome-icon
              v-if="orderStatus !== 1"
              class="text-color-secondary"
              icon="plus-circle"
              size="lg"
            />
            <font-awesome-icon
              v-if="orderStatus === 1"
              @click="addProductQuantity(product)"
              icon="plus-circle"
              size="lg"
            />
          </div>
        </div>
      </div>

      <div class="f-size-14 text-secondary">
        {{ product.barcode }}
      </div>

      <div class="d-flex">
        <div class="f-size-14 text-secondary">
          {{ product.price | numeral("0,0.00") }} x {{ product.quantity }}
        </div>
        <div class="ml-auto f-size-14 ">
          <template v-if="product.discountStikePrice !== 0">
            <del class="text-color-grey">
              {{ product.total | numeral("0,0.00") }}
            </del>
            <span class="ml-2">
              {{ product.stikePrice | numeral("0,0.00") }}
            </span>
          </template>
          <template v-else>
            {{ product.total | numeral("0,0.00") }}
          </template>
        </div>
      </div>

      <div
        class="mt-3 f-size-14 text-secondary"
        v-if="product.displayPromotions && product.displayPromotions.length > 0"
      >
        <div>
          <div class="">โปรโมชั่น</div>
        </div>
        <div class="mt-2">
          <div
            v-for="(promotion, index) in product.displayPromotions"
            :key="index"
          >
            <div class="text-small  text-left pl-1">
              <div>{{ index + 1 }}. {{ promotion.name }}</div>
              <div>
                ({{ promotion.startDate | moment($formatDate) }} -
                {{ promotion.endDate | moment($formatDate) }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ChatOrderProductCard",
  props: {
    orderStatus: Number,
    branchId: Number,
    product: Object,
    addProductQuantity: Function,
    removeProductQuantity: Function,
    isAction: Boolean
  },
  methods: {
    addCommas(x = 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
    }
  }
};
</script>

<style lang="scss" scoped>
.product-image {
  height: 80px;
  width: 80px;
  object-fit: contain;
  border: 1px solid #e8e7e7;
  background-color: white;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.f-size-16 {
  font-size: 16px;
}
</style>
